import type { GoogleAnalyticsEvent, GoogleAnalyticsPageview } from '@evenfinancial/even-ts-static';
import {
  EVEN_MESSAGE_PREFIX,
  FIONA_MESSAGE_PREFIX,
  GoogleAnalyticsEventTypes,
  LEAPLIFE_MESSAGE_PREFIX,
  PublishKey,
} from '@evenfinancial/even-ts-static';

import { ActionTypeKeys, fetchRateTablesSuccess, IFrameSentInitMessage, updateLead } from '../../actions';
import { GoogleAnalytics } from '../../services/GoogleAnalytics';

import type { Dispatch } from 'redux';

interface ChildMessage {
  data: { body?: string; type: string };
}

const pattern = RegExp(/^(?:even|fiona|leaplife)_([_a-z]+)$/);

export const handleParentEvents = (rawMessage: ChildMessage) => {
  const message = rawMessage.data;
  const fullMsgType = message.type;

  const type = pattern.exec(message.type)?.pop();
  if (type === undefined) return;
  switch (type) {
    case GoogleAnalyticsEventTypes.Event: {
      if (message.body !== undefined) {
        const event = JSON.parse(message.body) as GoogleAnalyticsEvent;
        GoogleAnalytics.sendEvent(event);
      }
      break;
    }
    case GoogleAnalyticsEventTypes.Pageview: {
      if (message.body !== undefined) {
        const pageview = JSON.parse(message.body) as GoogleAnalyticsPageview;
        GoogleAnalytics.sendPageview(pageview);
      }
      break;
    }

    case PublishKey.ApplicationComplete:
    case PublishKey.ApplicationLoaded:
    case PublishKey.CreditCardOfferClick:
    case PublishKey.DepositsOfferClick:
    case PublishKey.Impression:
    case PublishKey.LeadInitialized:
    case PublishKey.LifeInsuranceOfferClick:
    case PublishKey.LifeInsurancePhoneCallRequested:
    case PublishKey.LoanOfferAutoRedirect:
    case PublishKey.LoanOfferClick:
    case PublishKey.LeadPreQualifiedOrPreApproved:
    case PublishKey.RateTableDisplayed:
    case PublishKey.SpecialOfferClick:
      if (type === PublishKey.RateTableDisplayed) {
        if (message.body !== undefined) {
          const data = JSON.parse(message.body);
          return fetchRateTablesSuccess(data.product, data);
        }
      }

      if (type === PublishKey.Impression) {
        if (message.body !== undefined) {
          const data: { leadUuid: string; trackingUuid: string } = JSON.parse(message.body);
          return updateLead({
            leadUuid: data.leadUuid,
          });
        }
      }
      //TODO: Event Scripts
      // const eventScript = this.props.eventScripts?.find(({ eventName }) => eventName === type)
      //
      //  if (eventScript !== undefined) {
      //    let body = {}
      //    if (message.body !== undefined) {
      //      body = JSON.parse(message.body)
      //    }
      // this.eventScriptService.execute(eventScript, body)}

      break;
    case PublishKey.LifeInsuranceReportProductSubType:
    case PublishKey.StepSelected:
      if (message.body !== undefined) {
        return { ...JSON.parse(message.body), type };
      }
      break;
    case 'parent_append_url':
      const body = { ...JSON.parse(message?.body || '{}') };
      const url = new URL(location.href);
      url.searchParams.set(body.key, body.value);
      history.pushState(null, '', url);
      break;
    default:
      console.log('Ignoring Message');
  }
};

export const createMessageListener = (dispatch: Dispatch) => {
  return (event) => {
    const action = handleParentEvents(event);
    dispatch(action);
  };
};
