import type {
  Config,
  LifeInsuranceProductSubType,
  ParentData,
  Product,
  ProductStep,
  QueryParamKeys,
  TrackingKey,
} from '@evenfinancial/even-ts-static';
import { PublishKey } from '@evenfinancial/even-ts-static';

import type { Location } from 'history';
import type { TrustedPartner } from 'src/components/TrustedPartners';
import type { ApiResponse } from 'src/services/Api';

// Action type keys
export enum ActionTypeKeys {
  CALLED_GEOCODER = 'CALLED_GEOCODER',
  FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS',
  FETCH_RATE_TABLES_FAIL = 'FETCH_RATE_TABLES_FAIL',
  FETCH_RATE_TABLES_START = 'FETCH_RATE_TABLES_START',
  FETCH_RATE_TABLES_SUCCESS = 'FETCH_RATE_TABLES_SUCCESS',
  FETCH_TRUSTED_PARTNERS_FAIL = 'FETCH_TRUSTED_PARTNERS_FAIL',
  FETCH_TRUSTED_PARTNERS_SUCCESS = 'FETCH_TRUSTED_PARTNERS_SUCCESS',
  FILTERS_CHANGED = 'FILTERS_CHANGED',
  IFRAME_SENT_INIT_MESSAGE = 'IFRAME_SENT_INIT_MESSAGE',
  LOADED = 'LOADED',
  LOAD_CONFIG_BEGIN = 'LOAD_CONFIG_BEGIN',
  LOAD_PARAM = 'LOAD_PARAM',
  LOAD_PARENT_DATA = 'LOAD_PARENT_DATA',
  LOAN_OFFER_PREVIEW_SELECTED = 'LOAN_OFFER_PREVIEW_SELECTED',
  OFFER_AUTO_REDIRECT = 'OFFER_AUTO_REDIRECT',
  OFFER_CLICK = 'OFFER_CLICK',
  OFFER_MANUAL_REDIRECT = 'OFFER_MANUAL_REDIRECT',
  RATE_TABLE_CHANGED = 'RATE_TABLE_CHANGED',
  SET_MAX_RESULTS = 'SET_MAX_RESULTS',
  SET_RATE_TABLE_UUID = 'SET_RATE_TABLE_UUID',
  SORT_CHANGED = 'SORT_CHANGED',
  SORT_CLEARED = 'SORT_CLEARED',
  SPECIAL_OFFER_CLICK = 'SPECIAL_OFFER_CLICK',
  STEP_SELECTED = 'STEP_SELECTED',
  UPDATE_LEAD = 'UPDATE_LEAD',
}

// Action types
export interface CalledGeocoder {
  type: ActionTypeKeys.CALLED_GEOCODER;
}

export interface FetchOfferSuccess {
  data: unknown;
  product: Product;
  type: ActionTypeKeys.FETCH_OFFER_SUCCESS;
}

export interface FetchRateTablesStart {
  product: Product;
  type: ActionTypeKeys.FETCH_RATE_TABLES_START;
}

interface FetchRateTablesSuccessData {
  creditCardOffers: [];
  leadUuid: string;
  lifeInsuranceOffers: [];
  loanOffers: [];
  mortgageOffers: [];
  pendingOriginators: [];
  pendingResponses: [];
  savingsOffers: [];
  specialOffers: [];
  uuid: string;
}

export interface FetchRateTablesSuccess {
  data: ApiResponse<FetchRateTablesSuccessData>;
  product: Product;
  publishKey: PublishKey.RateTableDisplayed;
  type: ActionTypeKeys.FETCH_RATE_TABLES_SUCCESS;
}

export interface FetchRateTablesFailure {
  errorStatus?: number;
  product: Product;
  type: ActionTypeKeys.FETCH_RATE_TABLES_FAIL;
}

export interface FiltersChanged {
  filters: unknown;
  product: Product;
  type: ActionTypeKeys.FILTERS_CHANGED;
}

export interface IFrameSentInitMessage {
  type: ActionTypeKeys.IFRAME_SENT_INIT_MESSAGE;
}

export interface Loaded {
  trackingKey: TrackingKey.Loaded;
  type: ActionTypeKeys.LOADED;
}

export interface LoadConfigBegin {
  type: ActionTypeKeys.LOAD_CONFIG_BEGIN;
}

export interface LoadConfigSuccess {
  config: Config;
  type: ActionTypeKeys.LOAD_CONFIG_SUCCESS;
}

export interface LoadParam {
  param: QueryParamKeys;
  type: ActionTypeKeys.LOAD_PARAM;
  value: unknown;
}

export interface LoadParentData {
  data: ParentData;
  type: ActionTypeKeys.LOAD_PARENT_DATA;
}

export interface LoanOfferPreviewSelected {
  offer: unknown;
  type: ActionTypeKeys.LOAN_OFFER_PREVIEW_SELECTED;
}

export interface OfferAutoRedirect {
  offer: unknown;
  type: ActionTypeKeys.OFFER_AUTO_REDIRECT;
}

export interface OfferClick {
  publishKey: PublishKey.LoanOfferClick;
  type: ActionTypeKeys.OFFER_CLICK;
}

export interface OfferManualRedirect {
  offer: unknown;
  type: ActionTypeKeys.OFFER_MANUAL_REDIRECT;
}

export interface RateTableChanged {
  data: unknown;
  product: Product;
  type: ActionTypeKeys.RATE_TABLE_CHANGED;
}

export interface SetMaxResults {
  max: number;
  product: Product;
  type: ActionTypeKeys.SET_MAX_RESULTS;
}

export interface SetRateTableUuid {
  product: Product;
  rateTableUuid: string;
  type: ActionTypeKeys.SET_RATE_TABLE_UUID;
}

export interface SortChanged {
  direction?: unknown;
  product: Product;
  sort: unknown;
  type: ActionTypeKeys.SORT_CHANGED;
}

export interface SortCleared {
  product: Product;
  type: ActionTypeKeys.SORT_CLEARED;
}

export interface SpecialOfferClick {
  publishKey: PublishKey.SpecialOfferClick;
  type: ActionTypeKeys.SPECIAL_OFFER_CLICK;
}

export interface StepSelected {
  product: Product;
  step: ProductStep;
  type: ActionTypeKeys.STEP_SELECTED;
}

export interface UpdateLead {
  data: {
    leadUuid: string;
  };
  type: ActionTypeKeys.UPDATE_LEAD;
}

export type CommonActionTypes =
  | CalledGeocoder
  | FetchOfferSuccess
  | FetchRateTablesStart
  | FetchRateTablesSuccess
  | FetchRateTablesFailure
  | FetchTrustedPartnersFail
  | FetchTrustedPartnersSuccess
  | FiltersChanged
  | LoadConfigBegin
  | LoadConfigSuccess
  | LoadParam
  | LoadParentData
  | LoanOfferPreviewSelected
  | OfferAutoRedirect
  | OfferClick
  | OfferManualRedirect
  | RateTableChanged
  | SetMaxResults
  | SetRateTableUuid
  | SortChanged
  | SortCleared
  | SpecialOfferClick
  | StepSelected
  | UpdateLead;

export enum ActionTypeKeys {
  INIT_SESSION = 'INIT_SESSION',
  INIT_TRACKING = 'INIT_TRACKING',
  LIFE_INSURANCE_REPORT_PRODUCT_SUB_TYPE = 'LIFE_INSURANCE_REPORT_PRODUCT_SUB_TYPE',
  LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS',
  ROUTER_LOCATION_CHANGED = 'ROUTER_LOCATION_CHANGED',
  SET_PARTNER_PRODUCT_TYPES = 'SET_PARTNER_PRODUCT_TYPES',
}

// Action types
export interface LoadConfigSuccess {
  config: Config;
  type: ActionTypeKeys.LOAD_CONFIG_SUCCESS;
}

export interface FetchTrustedPartnersSuccess {
  partners: TrustedPartner[];
  type: ActionTypeKeys.FETCH_TRUSTED_PARTNERS_SUCCESS;
}

export interface FetchTrustedPartnersFail {
  type: ActionTypeKeys.FETCH_TRUSTED_PARTNERS_FAIL;
}

export interface RouterLocationChanged {
  location: Location;
  type: ActionTypeKeys.ROUTER_LOCATION_CHANGED;
}

export interface LifeInsuranceReportProductSubType {
  productSubType: LifeInsuranceProductSubType;
  type: ActionTypeKeys.LIFE_INSURANCE_REPORT_PRODUCT_SUB_TYPE;
}

export interface InitSession {
  sessionUuid?: string;
  type: ActionTypeKeys.INIT_SESSION;
}

export interface InitTracking {
  trackingUuid?: string;
  type: ActionTypeKeys.INIT_TRACKING;
}

export type ActionTypes =
  | FetchTrustedPartnersFail
  | FetchTrustedPartnersSuccess
  | LoadConfigSuccess
  | RouterLocationChanged
  | CommonActionTypes
  | IFrameSentInitMessage
  | LifeInsuranceReportProductSubType
  | InitSession
  | InitTracking;

export const loadConfigBegin = (): LoadConfigBegin => ({
  type: ActionTypeKeys.LOAD_CONFIG_BEGIN,
});

export const loadConfigSuccess = (config: Config): LoadConfigSuccess => ({
  config,
  type: ActionTypeKeys.LOAD_CONFIG_SUCCESS,
});

export const routerLocationChanged = (location: Location): RouterLocationChanged => ({
  location,
  type: ActionTypeKeys.ROUTER_LOCATION_CHANGED,
});

export const initSession = (sessionUuid?: string): InitSession => ({
  sessionUuid,
  type: ActionTypeKeys.INIT_SESSION,
});

export const initTracking = (trackingUuid?: string): InitTracking => ({
  trackingUuid,
  type: ActionTypeKeys.INIT_TRACKING,
});

export const fetchTrustedPartnersSuccess = (partners: TrustedPartner[]): FetchTrustedPartnersSuccess => ({
  partners,
  type: ActionTypeKeys.FETCH_TRUSTED_PARTNERS_SUCCESS,
});

export const fetchTrustedPartnersFail = (): FetchTrustedPartnersFail => ({
  type: ActionTypeKeys.FETCH_TRUSTED_PARTNERS_FAIL,
});

export const updateLead = (data: { leadUuid: string }): UpdateLead => ({
  type: ActionTypeKeys.UPDATE_LEAD,
  data,
});

export const fetchRateTablesSuccess = (
  product: Product,
  data: ApiResponse<FetchRateTablesSuccessData>
): FetchRateTablesSuccess => ({
  data,
  product,
  publishKey: PublishKey.RateTableDisplayed,
  type: ActionTypeKeys.FETCH_RATE_TABLES_SUCCESS,
});
export interface AvailableDemandPartner {
  demandSubAccountDisplayName: string;
  demandSubAccountId: number;
  demandSubAccountLogoUrl: string;
  demandSubAccountProductType: string;
  supplySubAccountId: number;
}
