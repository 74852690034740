import * as React from 'react';

export enum ThemeHeaderCopy {
  ALR = 'alr',
  SLR = 'slr',
  Standard = 'standard',
}

export enum ThemeHeaderLayout {
  Cobranded = 'cobranded',
  SingleLogo = 'singleLogo',
}

export interface Theme {
  backdropStyles: React.CSSProperties;
  borderRadiusLarge: string;
  borderRadiusSmall: string;
  boxStyles: React.CSSProperties;
  colorBackgroundPrimary: string;
  colorBackgroundSecondary: string;
  colorBorder: string;
  colorCta: string;
  colorError: string;
  colorPrimary: string;
  colorSecondary: string;
  colorTextPrimary: string;
  colorTextSecondary: string;
  ctaStyles: React.CSSProperties;
  errorStyles: React.CSSProperties;
  fontFamilyCTA: string;
  fontFamilyHeadline: string;
  fontFamilyLabels: string;
  fontFamilyPrimary: string;
  fontFamilySecondary: string;
  fontSizeBase: string;
  headerCopy: ThemeHeaderCopy;
  headerLayout: ThemeHeaderLayout;
  headlineStyles: React.CSSProperties;
  labelStyles: React.CSSProperties;
  letterSpacingCTA: string;
  letterSpacingLabels: string;
  partnerBrandDominant: boolean;
  partnerDisplayName: string;
  partnerLogo: string;
  partnerLogoLink: string;
  partnerLogoWidth: string;
  partnershipHeadingLogo: string;
  patternBackground: string;
}

export const ThemeContext = React.createContext<Theme | undefined>(undefined);

interface Props {
  children?: React.ReactNode;
  theme: Theme;
}

export const ThemeProvider = ({ children, theme }: Props) => (
  <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
);
