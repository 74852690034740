import { isDev } from 'src/common/config';

import type { GoogleAnalyticsEvent, GoogleAnalyticsPageview } from '@evenfinancial/even-ts-static';

export interface GoogleAnalyticDebug {
  trace: boolean;
}

declare global {
  interface Window {
    ga_debug: GoogleAnalyticDebug;
  }
}

export class GoogleAnalyticsService {
  private readonly client?: UniversalAnalytics.ga;

  constructor() {
    // tslint:disable-next-line: strict-type-predicates
    if (typeof ga !== 'undefined') {
      this.client = ga;
    }

    // If in development, prevent hits from being sent to Google Analaytics (but logged to console)
    if (isDev && this.client !== undefined) {
      ga('set', 'sendHitTask', null);

      // Use the line below to increase logging of GA events, helpful when checking the values of new events
      // window.ga_debug = { trace: true }
    }
  }

  public sendEvent = (event: GoogleAnalyticsEvent) => {
    if (this.client !== undefined) {
      this.client(
        'set',
        {
          page: event.path,
          title: event.title,
        },
        { ...event.fields }
      );

      this.client('send', 'event', event.category, event.action, event.label, event.count, {
        ...event.fields,
      });
    }
  };

  public sendPageview = (pageview: GoogleAnalyticsPageview) => {
    if (this.client !== undefined) {
      this.client(
        'set',
        {
          page: pageview.path,
          title: pageview.title,
        },
        { ...pageview.fields }
      );
      this.client('send', 'pageview');
    }
  };
}

export const GoogleAnalytics = new GoogleAnalyticsService();
