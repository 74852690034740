import type * as React from 'react';

import { useConfig } from '../../hooks/useConfig';
import { Brand, ConfigServiceDataKeys, FeatureFlag } from '../ConfigProvider';

import type { ConfigProductType } from '../ConfigProvider';

interface StoreProps {
  brand: Brand;
  enableNavBar: boolean;
  navBarProducts: ConfigProductType[];
  partnerKey: string;
  preferredOfferPartnerImageUrl: string;
  shouldShowPartnerLogo?: boolean;
  shouldShowPartnershipHeading: boolean;
}

interface OwnProps {
  children: (props: StoreProps) => React.ReactNode;
}

export const NavigationContainer = ({ children }: OwnProps) => {
  const config = useConfig();
  const featureFlags = config.featureFlags;
  const isPreferredView = featureFlags?.includes(FeatureFlag.SingleColumnLayout);

  if (!children) {
    return null;
  }

  return children({
    brand: config.brand ?? Brand.Even,
    enableNavBar:
      featureFlags?.includes(FeatureFlag.EnableNavBar) ||
      featureFlags?.includes(FeatureFlag.IncludeLandingPage) ||
      false,
    navBarProducts: config.navBarProducts ?? [],
    partnerKey: config[ConfigServiceDataKeys.partner] ?? '',
    preferredOfferPartnerImageUrl: config.preferredOfferPartnerData?.imageUrl ?? '',
    shouldShowPartnerLogo: !isPreferredView,
    shouldShowPartnershipHeading: !!config.shouldShowPartnershipHeading,
  });
};
