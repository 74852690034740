import { FeatureFlag } from 'src/components/ConfigProvider';

import type { ConfigServiceData } from 'src/components/ConfigProvider';
import type { Theme } from 'src/components/ThemeProvider';

const THEME_QUERY_PARAM_PREFIX = 'theme.';

const parseThemeConfig = (config: ConfigServiceData): Partial<Theme> => {
  // Take all config params and filter to only the ones that match actual
  // theme params, (formatted like e.g. theme.colorSecondary)
  const theme = Object.keys(config).reduce((acc, key) => {
    if (key.startsWith(THEME_QUERY_PARAM_PREFIX)) {
      acc[key] = config[key];
    }
    return acc;
  }, {});

  // Remove the namespace from the query param key (theme.colorSecondary => colorSecodary)
  const themeConfig: Partial<Theme> = Object.fromEntries(
    Object.entries(theme).map(([key, value]) => [key.replace(THEME_QUERY_PARAM_PREFIX, ''), value])
  );

  return themeConfig;
};

export const extractThemeFromConfig = (config: ConfigServiceData): Partial<Theme> => {
  const themeConfig = parseThemeConfig(config);

  const enableCustomBackground = config.featureFlags?.includes(FeatureFlag.EnableEmbedCustomBackground);
  const backdropStyles = enableCustomBackground
    ? themeConfig.backdropStyles
    : { backgroundColor: themeConfig.colorBackgroundSecondary };
  const boxStyles = enableCustomBackground ? { border: 'none', borderRadius: 'unset', boxShadow: 'none' } : {};

  return { ...themeConfig, backdropStyles, boxStyles };
};
