import styled from '@emotion/styled';

import type { Theme } from '@evenfinancial/consumer-ui';

export const themeToSiv = (theme: Theme) => {
  return styled.div`
    --border-color: ${theme.colorBorder};
    --color-emphasis: ${theme.colorPrimary};
    --background-color: ${theme.backdropStyles?.backgroundColor || 'transparent'};
    --border-radius: ${theme.borderRadiusLarge};
  `;
};
