import * as React from 'react';

import { css } from '@emotion/css';
import classNames from 'classnames';
import { ThemeConsumer } from 'src/components/ThemeConsumer';

import styles from './styles.module.scss';

export type ButtonHTMLType = 'anchor' | 'link' | 'navlink';
export type ButtonStyle = 'primary' | 'secondary' | 'contrast';

interface Props {
  children: React.ReactNode;
  customClass?: string;
  destination: string;
  style?: ButtonStyle;
  target?: string;
  type?: ButtonHTMLType;
}

export class Button extends React.PureComponent<Props> {
  public static defaultProps = {
    target: '',
  };

  public render() {
    const { children, customClass, destination, target, style = 'primary', type } = this.props;
    return (
      <ThemeConsumer>
        {({ ctaStyles, colorPrimary }) => {
          let theButtonStyles;
          if (style === 'primary') {
            theButtonStyles = css(`
              background: ${ctaStyles?.backgroundColor};
              border-color: ${ctaStyles?.backgroundColor};
            `);
          } else if (style === 'contrast') {
            theButtonStyles = css(`
              background: ${colorPrimary};
              border-color: ${colorPrimary};
            `);
          } else {
            theButtonStyles = css(`
              color: ${colorPrimary};
            `);
          }

          if (type === 'anchor') {
            return (
              <a
                className={classNames(styles.button, customClass, styles[style], `${theButtonStyles}`)}
                href={destination}
                target={target}
              >
                {children}
              </a>
            );
          } else {
            return (
              <button className={classNames(styles.button, customClass, styles[style], `${theButtonStyles}`)}>
                {children}
              </button>
            );
          }
        }}
      </ThemeConsumer>
    );
  }
}
