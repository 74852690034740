import * as React from 'react';

import { ThemeHeaderCopy } from '../../ThemeProvider';

import { StatsView } from './view';

import type { PartnerPageHeaderVariantStore } from '../../../common/types';

const copyTemplates: PartnerPageHeaderVariantStore = {
  [ThemeHeaderCopy.SLR]: [
    {
      text: '$500 - $500,000',
      title: 'Loans from',
    },
    {
      text: '60 - 240 months',
      title: 'Loan Terms',
    },
    {
      text: '1.88%',
      title: 'APR from',
    },
  ],
  [ThemeHeaderCopy.ALR]: [
    {
      text: '$10,000 - $100,000',
      title: 'Loans from',
    },
    {
      text: '24 - 84 months',
      title: 'Loan Terms',
    },
    {
      text: '2.49%',
      title: 'APR from',
    },
  ],
  [ThemeHeaderCopy.Standard]: [
    {
      text: '$250,000',
      title: 'Loans up to',
    },
    {
      text: '6 - 144 months',
      title: 'Loan Terms',
    },
    {
      text: '5.99%',
      title: 'APR from',
    },
  ],
};

const getStatsByCopyTemplate = (copyTemplateKey: ThemeHeaderCopy) =>
  copyTemplates[copyTemplateKey];

export const getStatsCopy = (copyTemplateKey: ThemeHeaderCopy) => {
  return getStatsByCopyTemplate(copyTemplateKey);
};

interface Props {
  companyUuid: string;
  copyTemplate: ThemeHeaderCopy;
}

export const StatsContainerView: React.FC<Props> = ({ copyTemplate }) => {
  return <StatsView copy={getStatsCopy(copyTemplate)} />;
};

// const mapStateToProps = (state: StateType) => ({
//   companyUuid: configCompanyUuidSelector(state) ?? '',
//   copyTemplate: configHeaderCopySelector(state),
// })

export const Stats = StatsContainerView;
