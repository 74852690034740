import React from 'react';

import { css, Global } from '@emotion/react';

import { default as OpenSansFontFace } from './font-face-declarations/opensans';

import type { Product } from '../ConfigProvider';

enum BreakpointSize {
  LG = '1200px',
  LGMax = '1199px',
  MD = '992px',
  MDMax = '991px',
  SM = '768px',
  SMMax = '767px',
  XS = '576px',
  XSMax = '575px',
}

interface Props {
  productType: Product | undefined;
}

// TODO: Find out why the unconnected styles require a connected provider
export const UnconnectedGlobalStyles = () => {
  const useAlternateBackgroundColor = false;
  return (
    <Global
      styles={[
        ({ colorBackgroundPrimary, colorBackgroundSecondary, colorTextPrimary, fontFamilyPrimary }) => css`
          ${OpenSansFontFace}

          html {
            font-size: 12px;
            line-height: 1.15;
          }

          body {
            background: ${useAlternateBackgroundColor ? colorBackgroundPrimary : colorBackgroundSecondary};
            color: ${colorTextPrimary};
            font-family: ${fontFamilyPrimary}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu,
              Cantarell, Helvetica Neue, sans-serif;
          }

          @media (max-width: ${BreakpointSize.XSMax}) {
            body {
              background: ${colorBackgroundPrimary};
            }
          }

          // Forms
          input,
          textarea {
            color: ${colorTextPrimary};
            font-family: ${fontFamilyPrimary};
          }
        `,
      ]}
    />
  );
};

export const GlobalStyles = () => {
  return <UnconnectedGlobalStyles />;
};
