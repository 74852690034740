import * as React from 'react';

import { css } from '@emotion/css';
import classnames from 'classnames';

// import { RenderDirection } from './container'
import styles from './styles.module.scss';

// Temporary
export enum RenderDirection {
  COLUMN = 'column',
  ROW = 'row',
}

export interface TrustedPartner {
  logoUrl: string;
  name: string;
  uuid?: string;
}

export interface TrustedPartners {
  direction?: RenderDirection;
  partners: TrustedPartner[];
}

export const TrustedPartners = ({ partners = [], direction = RenderDirection.ROW }: TrustedPartners): JSX.Element => {
  return (
    <div className={classnames(styles.content)} id="trusted-partner-container">
      <h4 className={classnames(styles.trustedPartnersHeader)}>Our Trusted Partners</h4>
      <div
        className={classnames(
          styles.trustedPartnersContainer,
          css`
            flex-direction: ${direction};
          `
        )}
      >
        {partners.map((partner) => (
          <img key={partner.uuid} alt={partner.name} id={partner.uuid} src={partner.logoUrl} />
        ))}
      </div>
    </div>
  );
};
