import * as React from 'react';
import { useMemo } from 'react';

import { useTheme } from '../../hooks/useTheme';

const fontBlacklist = new Set(['nunito', 'open sans', 'opensans']);

// TODO: load fewer fonts
export const getGoogleFontsLink = (fonts: string[]) =>
  `https://fonts.googleapis.com/css2?${fonts
    .map(
      (font) =>
        `family=${font.replace(
          / /g,
          '+'
        )}:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800`
    )
    .join('&')}&display=swap`;

export const GoogleFontsClient: React.FC = () => {
  const { fontFamilyPrimary, fontFamilySecondary } = useTheme();

  return useMemo(() => {
    const filteredFonts = [fontFamilyPrimary, fontFamilySecondary].filter(
      (font) => typeof font === 'string' && !fontBlacklist.has(font.toLowerCase())
    );
    return filteredFonts.length ? <link href={getGoogleFontsLink(filteredFonts)} rel="stylesheet" /> : null;
  }, [fontFamilyPrimary, fontFamilySecondary]);
};
