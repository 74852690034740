import { Brand } from '@evenfinancial/even-ts-static';

import { getBrandObject } from './utils';

// Generate an embed or consumer url
export const generateUrl = (isDevelopment: boolean, isStage: boolean, isEmbed: boolean, brand: Brand) => {
  const subdomainParts: string[] = [];
  if (isDevelopment === true) {
    subdomainParts.push('dev');
  }

  if (isStage === true) {
    subdomainParts.push('staging');
  }

  if (isEmbed === true) {
    subdomainParts.push('embed');
  }

  if (isEmbed === false && brand === Brand.Even) {
    subdomainParts.push('consumer');
  }

  const subdomain = subdomainParts.join('-');

  const { domain } = getBrandObject(brand);

  return subdomain.length === 0 ? `https://${domain}` : `https://${subdomain}.${domain}`;
};

export const generateBaseEmbedSsrUrl = (dev: boolean, preview: boolean, brand: Brand): string => {
  const subdomainParts = ['mps'];
  if (dev) {
    subdomainParts.unshift('dev');
  } else if (preview) {
    subdomainParts.unshift('preview');
  }
  const subdomain = subdomainParts.join('-');

  const { domain } = getBrandObject(brand);

  return `https://${subdomain}.${domain}`;
};

// Please don't export this out of this file
// We can keep this between you and me
const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

// Please don't use this - trying to deprecate
function shouldUseDev(): boolean {
  if (isBrowser()) {
    const hostname = window.location.hostname;
    return new RegExp(`dev`).test(hostname) || new RegExp(`localhost`).test(hostname);
  } else {
    return false;
  }
}

// isDev will be replaced by env variables at some point
// Do not use them in new code
export const isDev = shouldUseDev(); // shouldUseDev(this.document)

const prodPartnerpageUrl = 'https://partnerpage.evenfinancial.com';
const devPartnerpageUrl = 'http://dev-partnerpage.evenfinancial.com';
export const basePartnerpageUrl = isDev ? devPartnerpageUrl : prodPartnerpageUrl;

const devApiUrl = 'https://dev-api.evenfinancial.com';
const prodApiUrl = 'https://api.evenfinancial.com';

export const baseApiUrl = isDev ? devApiUrl : prodApiUrl;

export function urlSerialize(obj: object | unknown[]) {
  // tslint:disable-next-line: strict-type-predicates
  if (typeof obj !== 'object') {
    return encodeURIComponent(obj);
  }

  return (
    Object.keys(obj)
      // @ts-ignore
      .filter((key) => obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null)
      // @ts-ignore
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&')
  );
}
