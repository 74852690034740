import { datadogRum } from '@datadog/browser-rum-slim';

import { getEnvironmentValues } from '../config/Env';
import { getBrowserCookie } from '../utils/cookies';
import { isBrowser } from '../utils/isBrowser';

// These end up on the client anyway and have no read access so no reason to make them obfuscated.
const DD_CONFIG_VALUES = {
  applicationId: '678dc1ba-4a56-45e2-8f75-4acebdaa2a42',
  clientToken: 'pub4ddf8f11ec8ffb5ca2c0326e1b8eb677',
  site: 'datadoghq.com',
  service: 'cnf-legacy-renderer',
};

let initialized = false;
const initDatadog = () => {
  const env = getEnvironmentValues();
  if (!initialized && isBrowser && env.enableDdRum) {
    console.log('Initializing Datadog');
    datadogRum.init({
      ...DD_CONFIG_VALUES,
      env: env.environment,
      version: env.version,
      sampleRate: 100,
      premiumSampleRate: 0,
      replaySampleRate: 0,
      trackInteractions: false,
      allowedTracingOrigins: [
        'https://api.evenfinancial.com',
        'https://dev-api.evenfinancial.com',
      ],
    });
    addCNFTagsToDDContext({
      app: 'cnf-legacy-renderer',
      appVersion: env.version,
    });
    datadogRum.addRumGlobalContext('request_token', getBrowserCookie('efrc'));
    console.log('Datadog Ready');
    initialized = true;
  }
};

interface Ident {
  app: string;
  appVersion: string;
  environment?: string;
  version?: string;
}

/**
 * We'll reuse this
 * @param ident
 */
const addCNFTagsToDDContext = (ident: Ident) => {
  const STATIC_TAGS = { system: 'cnf' };
  Object.entries({ ...ident, ...STATIC_TAGS }).forEach(([key, value]) => {
    datadogRum.addRumGlobalContext(key, value);
  });
};

export const DatadogTools = {
  initDatadog,
  addCNFTagsToDDContext,
  addTiming: datadogRum.addTiming,
  addEvent: datadogRum.addAction,
  addContext: datadogRum.addRumGlobalContext,
  addError: datadogRum.addError,
};
