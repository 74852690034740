import { ThemeProvider } from '@emotion/react';
import App from 'next/app';

import type { Theme } from '@evenfinancial/consumer-ui';
import { GlobalStyles } from '@evenfinancial/consumer-ui';

import { Fonts } from '../components/fonts';
import { DatadogTools } from '../lib/Datadog';
import { themeToSiv } from '../lib/config/theme';

import type { AppContext, AppProps } from 'next/app';
import './consumer.css';
import './embed.css';
import './styles.css';

type ThemedProps = { theme: Theme };
DatadogTools.initDatadog();

const CustomApp = (props: AppProps<ThemedProps>) => {
  const { Component, pageProps } = props;
  const ThemeCSSVars = themeToSiv(props.pageProps.theme);
  DatadogTools.addTiming('next_app_render');
  DatadogTools.addEvent('page_view');

  return (
    <ThemeCSSVars>
      <ThemeProvider theme={pageProps.theme}>
        <Fonts />
        <GlobalStyles />
        <Component {...pageProps} />
      </ThemeProvider>
    </ThemeCSSVars>
  );
};

CustomApp.getInitialProps = async (ctx: AppContext) => {
  const props = await App.getInitialProps(ctx);
  //Used for pages like error that don't have a theme. TODO
  if (props.pageProps?.theme?.colorPrimary === undefined) {
    Object.assign(props.pageProps, { theme: {} });
  }
  return props;
};

export default CustomApp;
