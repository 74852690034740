import * as React from 'react';

import { useConfig } from 'src/hooks/useConfig';

import { ConfigServiceDataKeys, FeatureFlag, TrustedPartnersSectionLocation } from '../ConfigProvider';

import { TrustedPartners } from './index';

import type { Product } from '../ConfigProvider';
import type { TrustedPartner } from './index';

interface OwnProps {
  direction?: RenderDirection;
  staticTrustedPartners?: TrustedPartner[];
}

interface StoreProps {
  featureFlags: FeatureFlag[];
  product: Product;
  showTrustedPartners: TrustedPartnersSectionLocation;
  trustedPartners: TrustedPartner[];
}

export type TrustedPartnerContainerProps = OwnProps & StoreProps;

export enum RenderDirection {
  COLUMN = 'column',
  ROW = 'row',
}

export const TrustedPartnerContainer = ({ direction, staticTrustedPartners }: OwnProps) => {
  const config = useConfig();
  const featureFlags = config.featureFlags;
  const showTrustedPartners =
    (config[ConfigServiceDataKeys.showTrustedPartners] as TrustedPartnersSectionLocation) ??
    TrustedPartnersSectionLocation.None;
  const trustedPartners = config.trustedPartners ?? [];

  const shouldShowTrustedPartners = showTrustedPartners === TrustedPartnersSectionLocation.PartnerPage;
  const useStaticTrustedPartners = !featureFlags.includes(FeatureFlag.DynamicTrustedPartners);

  if (shouldShowTrustedPartners) {
    if (useStaticTrustedPartners && staticTrustedPartners) {
      return <TrustedPartners direction={direction} partners={staticTrustedPartners} />;
    } else {
      return trustedPartners.length > 0 ? <TrustedPartners direction={direction} partners={trustedPartners} /> : null;
    }
  } else {
    return null;
  }
};
