/* eslint @next/next/google-font-display: 0 */
/* eslint @next/next/google-font-preconnect:0 */
/* eslint @next/next/no-page-custom-font: 0 */
import { css, Global } from '@emotion/react';
import Head from 'next/head';

const BASE_URL = 'https://fiona.com/inline/fonts';

export const Fonts = () => {
  return (
    <>
      <Head>
        <link
          as="font"
          crossOrigin=""
          href={`${BASE_URL}/nunito/Nunito-Regular.woff2`}
          rel="preload"
          type="font/woff2"
        />
        <link
          as="font"
          crossOrigin=""
          href={`${BASE_URL}/nunito/Nunito-Bold.woff2`}
          rel="preload"
          type="font/woff2"
        />
      </Head>
      <Global styles={Nunito} />
    </>
  );
};

/* prettier-ignore */
const Nunito = css`
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    src: /*Local is best*/ local('Nunito Regular'), local('Nunito-Regular'),
      url('${BASE_URL}/nunito/Nunito-Regular.woff2') format('woff2'),
      /* Super Modern Browsers */
        url('${BASE_URL}/nunito/nunito-v12-latin-regular.woff2') format('woff2'),
      /* Modern Browsers */
        url('${BASE_URL}/nunito/nunito-v12-latin-regular.woff') format('woff'),
      /* Safari, Android, iOS */
        url('${BASE_URL}/nunito/nunito-v12-latin-regular.ttf')
        format('truetype'),
      /* Legacy - probably dead */
        url('${BASE_URL}/nunito/nunito-v12-latin-regular.eot?#iefix')
        format('embedded-opentype'),
      /* Legacy - probably dead */
        url('${BASE_URL}/nunito/nunito-v12-latin-regular.svg#Nunito')
        format('svg');
  }
  /* nunito-700 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Nunito Bold'), local('Nunito-Bold'),
      url('${BASE_URL}/nunito/Nunito-Bold.woff2') format('woff2'),
      /* Super Modern Browsers */
        url('${BASE_URL}/nunito/nunito-v12-latin-700.woff2') format('woff2'),
      /*  Modern Browsers */ url('${BASE_URL}/nunito/nunito-v12-latin-700.woff')
        format('woff'),
      /* Safari, Android, iOS */
        url('${BASE_URL}/nunito/nunito-v12-latin-700.ttf') format('truetype'),
      /* Legacy - probably dead  */
        url('${BASE_URL}/nunito/nunito-v12-latin-700.eot?#iefix')
        format('embedded-opentype'),
      /* Legacy - probably dead */
        url('${BASE_URL}/nunito/nunito-v12-latin-700.svg#Nunito') format('svg');
  }
`;
