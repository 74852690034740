// @ts-ignore: Implicit any
import * as cssColorConverter from 'css-color-converter';

import type { Config, QueryParamKeys } from '@evenfinancial/even-ts-static';
import {
  Brand,
  DefaultEvenConfig,
  DefaultFionaConfig,
  DefaultLeapLifeConfig,
  EVEN_MESSAGE_PREFIX,
  FIONA_MESSAGE_PREFIX,
  LEAPLIFE_MESSAGE_PREFIX,
  QueryParamParsers,
} from '@evenfinancial/even-ts-static';

import {
  allowedEmbedQueryParams,
  allowedQueryParams,
  APP_PREFIX,
  ConfigKeysExcludedFromEmbed,
  FILTER_PREFIX,
  TAG_PREFIX,
  THEME_PREFIX,
} from './constants';
// tslint:disable-next-line
const cssColor = cssColorConverter as any;

const evenBrandObject = {
  domain: 'evenfinancial.com',
  version: '7',
  defaultConfig: DefaultEvenConfig,
  messagePrefix: EVEN_MESSAGE_PREFIX,
} as const;
const fionaBrandObject = {
  domain: 'hifiona.com',
  version: '8',
  defaultConfig: DefaultFionaConfig,
  messagePrefix: FIONA_MESSAGE_PREFIX,
} as const;
const leaplifeBrandObject = {
  domain: 'leaplife.com',
  version: '9',
  defaultConfig: DefaultLeapLifeConfig,
  messagePrefix: LEAPLIFE_MESSAGE_PREFIX,
} as const;
export type BrandObject = typeof evenBrandObject | typeof fionaBrandObject | typeof leaplifeBrandObject;

export const getBrandObject = (brand: Brand): BrandObject =>
  brand === Brand.Even ? evenBrandObject : brand === Brand.LeapLife ? leaplifeBrandObject : fionaBrandObject;

enum BlackAndWhite {
  Black = '#000',
  White = '#fff',
}

interface GetContrastingColorOptions {
  black?: string;
  white?: string;
}

type GetContrastingColorReturnValue = BlackAndWhite | GetContrastingColorOptions[keyof GetContrastingColorOptions];
export const getContrastingColor = (
  configBackgroundColor?: string,
  options?: GetContrastingColorOptions
): GetContrastingColorReturnValue => {
  const white = options?.white ?? BlackAndWhite.White;
  const black = options?.black ?? BlackAndWhite.Black;
  const backgroundColor = configBackgroundColor ?? white;

  let textColor: GetContrastingColorReturnValue;
  try {
    const hexColor = cssColor(backgroundColor.toLowerCase()).toHexString();
    // The following is adapted from here: https://medium.com/@druchtie/contrast-calculator-with-yiq-5be69e55535c
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    if (yiq >= 180) {
      textColor = black;
    } else {
      textColor = white;
    }
  } catch (_) {
    textColor = white;
  }
  return textColor;
};

// tslint:disable-next-line: strict-type-predicates
export const isBrowser = (): boolean => typeof window !== 'undefined';

export const LazyLoadErrorCode = {
  ERROR_LAZY_LOAD_INVALID_ENVIRONMENT: 'ERROR_LAZY_LOAD_INVALID_ENVIRONMENT',
  ERROR_LAZY_LOAD_SCRIPT_DUPLICATE: 'ERROR_LAZY_LOAD_SCRIPT_DUPLICATE',
  ERROR_LAZY_LOAD_SCRIPT_ERROR: 'ERROR_LAZY_LOAD_SCRIPT_ERROR',
} as const;

export interface LazyLoadError extends Error {
  code: keyof typeof LazyLoadErrorCode;
  scriptId: string;
}

// TODO: use normal errors
const createLazyLoadError = (
  code: keyof typeof LazyLoadErrorCode,
  scriptId: string,
  existingError?: Error | undefined
): LazyLoadError => {
  let errorMessage = '';
  switch (code) {
    case LazyLoadErrorCode.ERROR_LAZY_LOAD_INVALID_ENVIRONMENT:
      errorMessage = existingError?.message ?? 'Script tried to load when not in browser';
      break;
    case LazyLoadErrorCode.ERROR_LAZY_LOAD_SCRIPT_DUPLICATE:
      errorMessage = existingError?.message ?? 'Script with given id already exists';
      break;
    case LazyLoadErrorCode.ERROR_LAZY_LOAD_SCRIPT_ERROR:
      errorMessage = existingError?.message ?? 'Script encountered an error while loading';
      break;
    default:
  }
  return Object.assign(existingError ?? new Error(`${code}: ${errorMessage}`), {
    code,
    scriptId,
  });
};

export const isLazyLoadErrorType = (error: unknown): error is LazyLoadError => {
  return (
    // tslint:disable-next-line: no-any
    error instanceof Error && (error as any).code in LazyLoadErrorCode && typeof (error as any).scriptId === 'string'
  );
};

export const lazyLoadScript = async (scriptId: string, scriptSrc: string, throwIfDuplicate?: boolean) =>
  new Promise<LazyLoadError | void>((resolve, reject) => {
    if (isBrowser()) {
      if (document.getElementById(scriptId) === null) {
        const helper = document.createElement('script');
        helper.id = scriptId;
        helper.src = scriptSrc;
        helper.async = true;
        helper.defer = true;
        helper.setAttribute('importance', 'low');
        helper.onload = () => {
          resolve();
        };
        helper.onerror = (event, source, line, col, error) => {
          reject(createLazyLoadError(LazyLoadErrorCode.ERROR_LAZY_LOAD_SCRIPT_ERROR, scriptId, error));
        };
        document.head.appendChild(helper);
      } else {
        const err = createLazyLoadError(LazyLoadErrorCode.ERROR_LAZY_LOAD_SCRIPT_DUPLICATE, scriptId);
        if (throwIfDuplicate) {
          reject(err);
        } else {
          resolve(err);
        }
      }
    } else {
      reject(createLazyLoadError(LazyLoadErrorCode.ERROR_LAZY_LOAD_INVALID_ENVIRONMENT, scriptId));
    }
  });

export const omit = (object: object, excludeKeysList: string[]): object => {
  // tslint:disable-next-line: no-inferred-empty-object-type
  return Object.keys(object).reduce((acc, key) => {
    if (!excludeKeysList.includes(key)) {
      return { ...acc, [key]: object[key] };
    }
    return acc;
  }, {});
};

export const pickBy = (object: object, validateFunction: (value: string) => boolean): object => {
  // tslint:disable-next-line: no-inferred-empty-object-type
  return Object.keys(object).reduce((acc, key) => {
    if (validateFunction(object[key])) {
      return { ...acc, [key]: object[key] };
    }
    return acc;
  }, {});
};

// TODO: signpost style allowed ma«tchers
export const getAllowedQPConfig = ({
  queryParams,
  embedOnly,
}: {
  embedOnly?: boolean;
  queryParams: {};
}): Partial<Config> => {
  const allowList = embedOnly ? allowedEmbedQueryParams : allowedQueryParams;
  // tslint:disable-next-line no-inferred-empty-object-type
  const allowedConfig = Object.entries(queryParams).reduce((acc, [key, value]) => {
    if (
      allowList.includes(key as QueryParamKeys) ||
      key.startsWith(APP_PREFIX) ||
      key.startsWith(FILTER_PREFIX) ||
      key.startsWith(TAG_PREFIX) ||
      key.startsWith(THEME_PREFIX)
    ) {
      const parser = QueryParamParsers[key];
      const val = parser ? parser(value as string) : value;
      return { ...acc, [key]: val };
    } else {
      return acc;
    }
  }, {});
  const cleanedList = pickBy(allowedConfig, (value: string) => value !== '');
  if (embedOnly) {
    return omit(cleanedList, ConfigKeysExcludedFromEmbed);
  }
  return cleanedList;
};
