import * as React from 'react';

import { css } from '@emotion/css';
import classNames from 'classnames';
import { Button } from 'src/components/Button';
import { GenericPage } from 'src/components/GenericPage';

import { BreakpointSize } from '@evenfinancial/even-ts-static';

import Fourohfour from './fourohfour.svg';
import styles from './styles.module.scss';

export const PartnerPageNotFound = () => {
  return (
    <GenericPage>
      <div className={classNames(styles.errorPage)}>
        <div
          className={classNames(
            styles.icon,
            css`
              svg {
                width: 75px;

                @media (max-width: ${BreakpointSize.XSMax}) {
                  width: 48px;
                }
              }
            `
          )}
        >
          <Fourohfour />
        </div>
        <h2>Page not found</h2>
        <p>Unfortunately the page you are looking for does not exist.</p>
        <div className={styles.cta}>
          <Button destination="https://fiona.com?utm_source=consumer_404" style="primary" type="anchor">
            Back Home
          </Button>
        </div>
      </div>
    </GenericPage>
  );
};
