import React from 'react';

import { ThemeProvider } from '@emotion/react';

import { ConfigProvider } from '../ConfigProvider';
import { GoogleFontsClient } from '../GoogleFontsClient';

import type { AugmentedConfig } from '../ConfigProvider';
import type { Theme } from '@emotion/react';

interface Props {
  children: React.ReactNode;
  config: AugmentedConfig;
  theme?: Theme;
}

export const ConsumerWrapper = ({ config, children, theme }: Props) => {
  const wrappedComponents = (
    <ConfigProvider config={config}>
      <GoogleFontsClient />
      {children}
    </ConfigProvider>
  );

  return theme ? <ThemeProvider theme={theme}>{wrappedComponents}</ThemeProvider> : wrappedComponents;
};
