import * as React from 'react';

import { css } from '@emotion/css';
import classNames from 'classnames';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { ThemeConsumer } from 'src/components/ThemeConsumer';
import { useConfig } from 'src/hooks/useConfig';

import Achieve from '../../assets/images/partners-loans/achieve.png';
import Avant from '../../assets/images/partners-loans/avant.png';
import BestEgg from '../../assets/images/partners-loans/bestegg.png';
import CommonBond from '../../assets/images/partners-loans/commonbond.png';
import HappyMoney from '../../assets/images/partners-loans/happymoney.png';
import LendingClub from '../../assets/images/partners-loans/lendingclub.png';
import LendingPoint from '../../assets/images/partners-loans/lendingpoint.png';
import LendKey from '../../assets/images/partners-loans/lendkey.png';
import LightStream from '../../assets/images/partners-loans/lightstream.png';
import PenFed from '../../assets/images/partners-loans/penfed.png';
import Prosper from '../../assets/images/partners-loans/prosper.png';
import Sofi from '../../assets/images/partners-loans/sofi.png';
import Upgrade from '../../assets/images/partners-loans/upgrade.png';
import { ConfigServiceDataKeys, TrustedPartnersSectionLocation } from '../ConfigProvider';
import { ThemeHeaderCopy } from '../ThemeProvider';
import { RenderDirection, TrustedPartnerContainer } from '../TrustedPartners/container';

import styles from './styles.module.scss';

import type { FeatureFlag } from '../ConfigProvider';
import type { TrustedPartner } from '../TrustedPartners';
const SOFI_UUID = '5e210fea-d7a2-47bf-b44c-38f5590ecf83';
const FRESH_START_FAST_UUID = '77d9b45c-2cd2-489a-a08b-10a0c93cd245';
const ACHIEVE: TrustedPartner = {
  logoUrl: Achieve,
  name: 'Achieve',
  uuid: 'aa80f86b-b438-4173-a0ff-4e2a273c3d5b',
};
const AVANT: TrustedPartner = {
  logoUrl: Avant,
  name: 'Avant',
  uuid: '	d6d6e812-a1c4-46c8-8296-d674115b4099',
};

const BEST_EGG: TrustedPartner = {
  logoUrl: BestEgg,
  name: 'Best Egg',
  uuid: 'e8321746-1e5f-4981-bca6-be5e84782528',
};

const COMMON_BOND: TrustedPartner = {
  logoUrl: CommonBond,
  name: 'Common Bond',
  uuid: '77d6e1e3-759a-48bb-ba6c-d893477bc93f',
};

const LENDING_CLUB: TrustedPartner = {
  logoUrl: LendingClub,
  name: 'Lending Club',
  uuid: '46200e2f-ad61-42a5-a8b6-b578bdf453ad',
};

const LEND_KEY: TrustedPartner = {
  logoUrl: LendKey,
  name: 'Lend Key',
  uuid: '264172c7-89b3-4b10-b8ff-61e4edf1423f',
};

const LENDING_POINT: TrustedPartner = {
  logoUrl: LendingPoint,
  name: 'Lending Point',
  uuid: 'a3ac7be1-dc8d-4f73-a417-d381e851a3d3',
};

const LIGHTSTREAM: TrustedPartner = {
  logoUrl: LightStream,
  name: 'LightStream',
  uuid: '57f79ac4-cefe-4918-8786-d87c0e45026b',
};

const HAPPYMONEY: TrustedPartner = {
  logoUrl: HappyMoney,
  name: 'Happy Money',
  uuid: 'f3ddaa43-baa5-4bbb-bb35-806cd33b6625',
};

const PENFED: TrustedPartner = {
  logoUrl: PenFed,
  name: 'PenFed Credit Union',
  uuid: '1f1177f7-cba2-4b7a-8a53-258003d87549',
};

const PROSPER: TrustedPartner = {
  logoUrl: Prosper,
  name: 'Prosper',
  uuid: 'aff0bf73-38ff-428b-95e1-92cf003a3a1e',
};

const SOFI: TrustedPartner = {
  logoUrl: Sofi,
  name: 'SoFi',
  uuid: 'd69e3813-7f29-486b-8c1d-3de6e3e043c0',
};

const UPGRADE: TrustedPartner = {
  logoUrl: Upgrade,
  name: 'Upgrade',
  uuid: '4499a3b5-caaf-470f-9719-2c5ba9c731b9',
};

export const DEFAULT_LOAN_PARTNERS = [
  ACHIEVE,
  AVANT,
  BEST_EGG,
  LENDING_CLUB,
  LENDING_POINT,
  LIGHTSTREAM,
  HAPPYMONEY,
  PROSPER,
  SOFI,
  UPGRADE,
];

// Sans Freedom Plus and Sofi
const SOFI_LOAN_PARTNERS = [AVANT, BEST_EGG, LENDING_CLUB, LENDING_POINT, LIGHTSTREAM, HAPPYMONEY, PROSPER, UPGRADE];

// Sans Best Egg
const FRESH_START_FAST_PARTNERS = [
  ACHIEVE,
  AVANT,
  LENDING_CLUB,
  LENDING_POINT,
  LIGHTSTREAM,
  HAPPYMONEY,
  PROSPER,
  SOFI,
  UPGRADE,
];

export const SLR_PARTNERS = [COMMON_BOND, LEND_KEY, PENFED, SOFI];

const loanPartnerLogosByCopyVariant = {
  [ThemeHeaderCopy.SLR]: SLR_PARTNERS,
  [ThemeHeaderCopy.Standard]: DEFAULT_LOAN_PARTNERS,
};

export const getLoanPartnerLogosByCopyVariant = (copyVariant: ThemeHeaderCopy) =>
  loanPartnerLogosByCopyVariant[copyVariant];

interface OwnProps {
  children?: React.ReactNode;
  showLendingPartners?: boolean;
  title?: string;
}

interface StoreProps {
  companyUuid: string | undefined;
  copyVariant: ThemeHeaderCopy;
  featureFlags: FeatureFlag[];
  showTrustedPartners: TrustedPartnersSectionLocation;
}

type Props = OwnProps & StoreProps;

const SidebarView: React.FC<Props> = ({
  children,
  companyUuid,
  copyVariant,
  showLendingPartners,
  showTrustedPartners,
  title,
}) => {
  let trustedLoanPartnersList: TrustedPartner[];

  if (companyUuid === SOFI_UUID) {
    trustedLoanPartnersList = SOFI_LOAN_PARTNERS;
  } else if (companyUuid === FRESH_START_FAST_UUID) {
    trustedLoanPartnersList = FRESH_START_FAST_PARTNERS;
  } else {
    trustedLoanPartnersList = getLoanPartnerLogosByCopyVariant(copyVariant);
  }

  const showTrustedPartnersSection =
    showLendingPartners && showTrustedPartners === TrustedPartnersSectionLocation.PartnerPage;
  return (
    <ThemeConsumer>
      {({
        colorBackgroundSecondary,
        colorBorder,
        colorPrimary,
        colorTextPrimary,
        fontFamilyPrimary,
        fontFamilySecondary,
      }) => (
        <div
          className={classNames(
            styles.sidebar,
            css`
              border-left-color: ${colorBackgroundSecondary};

              h2,
              p {
                color: ${colorTextPrimary};
              }
              h3 {
                color: ${colorPrimary};
              }
              h2,
              h3 {
                font-family: ${fontFamilyPrimary};
              }
              p {
                font-family: ${fontFamilySecondary};
              }
            `
          )}
        >
          <Grid>
            <Row>
              <Col sm={11} smOffset={1}>
                <h2>{title}</h2>
                {children}
                {showTrustedPartnersSection && (
                  <div
                    className={classNames(
                      styles.showPartners,
                      css`
                        border-top-color: ${colorBorder};
                      `
                    )}
                  >
                    <TrustedPartnerContainer
                      direction={RenderDirection.COLUMN}
                      staticTrustedPartners={trustedLoanPartnersList}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
      )}
    </ThemeConsumer>
  );
};

export const Sidebar = () => {
  const config = useConfig();
  const companyUuid = config['credentials.company_uuid'] ?? '';
  const copyVariant = config['theme.headerCopy'] ?? ThemeHeaderCopy.Standard;
  const featureFlags = config.featureFlags;
  const showTrustedPartners =
    (config[ConfigServiceDataKeys.showTrustedPartners] as TrustedPartnersSectionLocation) ??
    TrustedPartnersSectionLocation.None;
  return (
    <SidebarView
      companyUuid={companyUuid}
      copyVariant={copyVariant}
      featureFlags={featureFlags}
      showTrustedPartners={showTrustedPartners}
    />
  );
};
