import * as React from 'react';

import { useTheme } from '@emotion/react';

import type { Theme } from '@emotion/react';

export interface Props {
  children: (props: Theme) => React.ReactNode;
}

export const ThemeConsumer = ({ children }: Props) => {
  const theme = useTheme();
  return <React.Fragment>{children(theme)}</React.Fragment>;
};
