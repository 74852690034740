import * as React from 'react';

import { ConfigServiceData, FeatureFlag } from '@evenfinancial/even-ts-static';

import type { TrustedPartner } from '../TrustedPartners';

export enum ConfigServiceDataKeys {
  advertiserDisclosureCopy = 'advertiserDisclosureCopy',
  allowPersonalizedLinkout = 'allowPersonalizedLinkout',
  applicationHeaderTitleText = 'application_header_title_text',
  brand = 'brand',
  clientTags = 'clientTags',
  credentialsCompanyUuid = 'credentials.company_uuid',
  creditCardApplicationType = 'credit_card_application_type',
  creditCardRewardsEnabled = 'credit_card_rewards_enabled',
  defaultLoanPurpose = 'app.purpose',
  embedScripts = 'embedScripts',
  eventScripts = 'eventScripts',
  featureFlags = 'featureFlags',
  headScripts = 'headScripts',
  headerText = 'headerText',
  inPartnershipWithFiona = 'pw_fiona',
  incentives_enabled = 'incentives_enabled',
  integrationStatus = 'integrationStatus',
  leadUuid = 'leadUuid',
  loanPurposeOptions = 'loanPurposeOptions',
  partner = 'partner',
  partnerDisclaimer = 'partnerDisclaimer',
  plaidEnabled = 'plaid_enabled',
  productType = 'productType',
  shouldAllowFionaAccount = 'shouldAllowFionaAccount',
  shouldAllowLoanCoApplicant = 'shouldAllowLoanCoApplicant',
  shouldAutoRedirect = 'shouldAutoRedirect',
  shouldDisplayNavigation = 'shouldDisplayNavigation',
  shouldShowPartnershipHeading = 'shouldShowPartnershipHeading',
  showTrustedPartners = 'show_trusted_partners',
  themeBackdropStyles = 'theme.backdropStyles',
  themeCtaStyles = 'theme.ctaStyles',
  themeErrorStyles = 'theme.errorStyles',
  themeFixedAppWidth = 'theme.fixedAppWidth',
  themeFloatingLabelInputs = 'theme.floatingLabelInputs',
  themeHeaderLayout = 'theme.headerLayout',
  themeHeadlineStyles = 'theme.headlineStyles',
  themeLabelStyles = 'theme.labelStyles',
  themePartnerBrandDominant = 'theme.partnerBrandDominant',
  themePartnerLogo = 'theme.partnerLogo',
  themePartnerLogoLink = 'theme.partnerLogoLink',
  themePartnerLogoWidth = 'theme.partnerLogoWidth',
  themePartnershipHeadingLogo = 'theme.partnershipHeadingLogo',
  uiName = 'uiName',
  version = 'version',
}

export enum LoanPurposeOptions {
  AUTO = 'auto',
  AUTO_REFINANCE = 'auto_refinance',
  BABY = 'baby',
  BOAT = 'boat',
  BUSINESS = 'business',
  CREDIT_CARD_REFINANCE = 'credit_card_refi',
  DEBT_CONSOLIDATION = 'debt_consolidation',
  GREEN = 'green',
  HOME_IMPROVEMENT = 'home_improvement',
  HOUSEHOLD_EXPENSE = 'household_expenses',
  LARGE_PURCHASE = 'large_purchases',
  MEDICAL = 'medical_dental',
  MOVING = 'moving_relocation',
  OTHER = 'other',
  SPECIAL_OCCASSION = 'special_occasion',
  STUDENT = 'student_loan',
  STUDENT_REFINANCE = 'student_loan_refi',
  TAXES = 'taxes',
  VACATION = 'vacation',
  WEDDING = 'wedding',
}

export enum IntegrationStatusValues {
  Deactivated = 'deactivated',
  Draft = 'draft',
  Live = 'live',
}

export enum Brand {
  Even = 'even',
  Fiona = 'fiona',
  LeapLife = 'leaplife',
}

export interface ClientTags {
  [key: string]: string[];
}

export enum ApplicationType {
  SingleStep = 'single_step',
  StepByStep = 'step_by_step',
}

export enum EventScriptType {
  Javascript = 'JAVASCRIPT',
  Url = 'URL',
}

export interface EventScript {
  eventName: string;
  id: string;
  payload: string;
  type: EventScriptType;
}

export enum Product {
  CreditCard = 'credit_card',
  Deposits = 'deposits',
  LifeInsurance = 'life_insurance',
  Loan = 'loan',
  Mortgage = 'mortgage',
  Other = 'other',
}

export enum TrustedPartnersSectionLocation {
  Embed = 'embed',
  None = 'none',
  PartnerPage = 'partner_page',
}

export enum ConfigProductType {
  AutomobileRefinance = 'automobile_refinance',
  CreditCard = 'credit_card',
  Deposits = 'savings',
  LifeInsurance = 'life_insurance',
  Loan = 'loan',
  Mortgage = 'mortgage',
  SpecialOffers = 'special_offers',
  StudentLoanRefinance = 'student_loan_refinance',
}

export interface PreferredOfferPartnerData {
  companyUuid: string;
  displayName: string;
  imageUrl: string;
}

export { ConfigServiceData };
// From: src/common/types.ts, replaced Config with ConfigServiceData
// A partner config with derived information added in
export type AugmentedConfig = { [P in Exclude<keyof ConfigServiceData, 'featureFlags'>]?: ConfigServiceData[P] } & {
  baseApiUrl: string;
  featureFlags: FeatureFlag[];
  leadUuid?: string;
  navBarProducts?: ConfigProductType[];
  partnerKey?: string;
  preferredOfferPartnerData?: PreferredOfferPartnerData;
  trustedPartners?: TrustedPartner[] | undefined;
};

export const ConfigContext = React.createContext<AugmentedConfig | undefined>(undefined);

interface Props {
  children?: React.ReactNode;
  config: AugmentedConfig;
}

export const ConfigProvider = ({ children, config }: Props) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

export { FeatureFlag };
