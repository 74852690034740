import * as React from 'react';

import { Col, Grid, Row } from 'react-flexbox-grid';
import { ConfigServiceDataKeys } from 'src/components/ConfigProvider';
import { ThemeConsumer } from 'src/components/ThemeConsumer';
import { TrustedPartnerContainer } from 'src/components/TrustedPartners/container';
import { useConfig } from 'src/hooks/useConfig';

import { Brand, TrustedPartnersSectionLocation } from '@evenfinancial/even-ts-static';

import SavingsIcon from '../../../assets/components/savings.svg';
import Stopwatch from '../../../assets/components/stopwatch.svg';
import Thumbsup from '../../../assets/components/thumbsup.svg';

import styles from './styles.module.scss';

import type { TrustedPartner } from 'src/components/TrustedPartners';

interface Props {
  bridgePartner: boolean;
  children: React.ReactNode;
  headerText?: string;
  isEven: boolean;
  shouldShowPartnershipHeading: boolean | undefined;
  showTrustedPartners: TrustedPartnersSectionLocation;
}

const DEFAULT_SAVINGS_TRUSTED_PARTNERS: TrustedPartner[] = [
  {
    name: 'Aspiration',
    logoUrl: 'https://images.evenfinancial.com/logos/aspiration/aspiration.jpg',
    uuid: 'd71a939c-ea39-48d3-832f-29918a2577c7',
  },
  {
    name: 'Nationwide My Savings',
    logoUrl: 'https://s3.amazonaws.com/images.evenfinancial.com/logos/prod/nationwide_high_yield_savings.png',
    uuid: '6e88c5c6-68c8-4080-8192-f6658be57860',
  },
  {
    name: 'Empower Savings - Demand',
    logoUrl: 'https://s3.amazonaws.com/images.evenfinancial.com/logos/prod/empower_savings_-_demand-270.png',
    uuid: '8135230b-f277-4e9d-9120-5fffe44c4a53',
  },
  {
    name: 'UFB High Yield Savings',
    logoUrl: 'https://images.evenfinancial.com/logos/ufbdirect/ufbdirect.svg',
    uuid: 'a897bd82-bc14-4cc7-af9c-6f6d6fb29026',
  },
];

export class SavingsPartnerPageView extends React.Component<Props> {
  public static Path = (partnerKey?: string) => `/${partnerKey}/savings`;

  public render() {
    const {
      bridgePartner,
      children,
      headerText = `Compare the Top Savings Accounts of ${new Date().getFullYear()}`,
      showTrustedPartners,
    } = this.props;
    const showTrustedPartnersSection = showTrustedPartners === TrustedPartnersSectionLocation.PartnerPage;

    return (
      <ThemeConsumer>
        {({
          colorBackgroundPrimary,
          colorPrimary,
          colorTextPrimary,
          colorTextSecondary,
          fontFamilyPrimary,
          fontFamilySecondary,
          headlineStyles,
        }) => (
          <div
            className={styles.preferredPartnerPage}
            data-test-id="savings-page"
            style={{ backgroundColor: colorBackgroundPrimary }}
          >
            <div className={styles.preferredPartnerPageInner} style={{ backgroundColor: colorBackgroundPrimary }}>
              <Grid>
                {!bridgePartner && (
                  <Row>
                    <Col xs className={styles.hero}>
                      <div
                        className={styles.tagline}
                        data-test-id="savings-header-text"
                        style={{ color: colorTextPrimary, ...headlineStyles }}
                      >
                        {headerText}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row center="xs" className={styles.multiProductWidget}>
                  <Col lg={9} md={8} xs={12}>
                    {children}
                  </Col>
                </Row>
                <Row center="xs">
                  <Col className={styles.instructions} xs={10}>
                    <div className={styles.item}>
                      <div className={styles.icon} style={{ background: colorPrimary }}>
                        <SavingsIcon />
                      </div>
                      <div className={styles.content}>
                        <h3
                          style={{
                            color: colorTextPrimary,
                            fontFamily: fontFamilyPrimary,
                          }}
                        >
                          Grow your Money
                        </h3>
                        <p
                          style={{
                            color: colorTextSecondary,
                            fontFamily: fontFamilySecondary,
                          }}
                        >
                          Find the top high interest savings accounts to grow your money.
                        </p>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.icon} style={{ background: colorPrimary }}>
                        <Stopwatch />
                      </div>
                      <div className={styles.content}>
                        <h3
                          style={{
                            color: colorTextPrimary,
                            fontFamily: fontFamilyPrimary,
                          }}
                        >
                          It's Quick
                        </h3>
                        <p
                          style={{
                            color: colorTextSecondary,
                            fontFamily: fontFamilySecondary,
                          }}
                        >
                          It takes seconds to compare rates from many top providers.
                        </p>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.icon} style={{ background: colorPrimary }}>
                        <Thumbsup />
                      </div>
                      <div className={styles.content}>
                        <h3
                          style={{
                            color: colorTextPrimary,
                            fontFamily: fontFamilyPrimary,
                          }}
                        >
                          Simple &amp; Easy Setup
                        </h3>
                        <p
                          style={{
                            color: colorTextSecondary,
                            fontFamily: fontFamilySecondary,
                          }}
                        >
                          Open your new account online in minutes.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
            {showTrustedPartnersSection && (
              <Grid>
                <Row center="xs">
                  <Col md={10} xs={12}>
                    <TrustedPartnerContainer staticTrustedPartners={DEFAULT_SAVINGS_TRUSTED_PARTNERS} />
                  </Col>
                </Row>
              </Grid>
            )}
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

interface SavingsPartnerPageProps {
  children?: React.ReactNode;
}

export const SavingsPartnerPage = ({ children }: SavingsPartnerPageProps) => {
  const config = useConfig();
  const bridgePartner = !!config.bridge_partner;
  const headerText = config.headerText;
  const isEven = config.brand === Brand.Even;
  const shouldShowPartnershipHeading = config.shouldShowPartnershipHeading;
  const showTrustedPartners =
    (config[ConfigServiceDataKeys.showTrustedPartners] as TrustedPartnersSectionLocation) ??
    TrustedPartnersSectionLocation.None;

  return (
    <SavingsPartnerPageView
      bridgePartner={bridgePartner}
      headerText={headerText}
      isEven={isEven}
      shouldShowPartnershipHeading={shouldShowPartnershipHeading}
      showTrustedPartners={showTrustedPartners}
    >
      {children}
    </SavingsPartnerPageView>
  );
};
