import { ConfigKeys, ConfigServiceDataKeys, QueryParamKeys } from '@evenfinancial/even-ts-static';

export const APP_PREFIX = 'app.';
export const FILTER_PREFIX = 'filter.';
export const TAG_PREFIX = 'tag.';
export const THEME_PREFIX = 'theme.';

export const allowedEmbedQueryParams = [
  ConfigKeys.ResultsUuid,
  ConfigServiceDataKeys.featureFlags,
  ConfigServiceDataKeys.headerText,
  ConfigServiceDataKeys.loanPurposeOptions,
  ConfigServiceDataKeys.shouldAllowLoanCoApplicant,
  ConfigServiceDataKeys.shouldAutoRedirect,
  QueryParamKeys.BridgePartner,
  QueryParamKeys.CreditCardFlow,
  QueryParamKeys.CreditCardRewardsEnabled,
  QueryParamKeys.DepositsFlow,
  QueryParamKeys.EmbedVersionOverride,
  QueryParamKeys.FilterStyle,
  QueryParamKeys.LoanFlow,
  QueryParamKeys.Offer,
  QueryParamKeys.OfferOverflow,
  QueryParamKeys.OfferStyle,
  QueryParamKeys.PageSize,
  QueryParamKeys.PreferredOfferPartner,
  QueryParamKeys.ShowTrustedPartners,
  QueryParamKeys.Step,
  QueryParamKeys.MaxResults,
  'loan_sort_option',
];

export const allowedConsumerOnlyQueryParams = [ConfigServiceDataKeys.shouldShowPartnershipHeading];

export const allowedQueryParams = [...allowedEmbedQueryParams, ...allowedConsumerOnlyQueryParams];

export const ConfigKeysExcludedFromEmbed = [
  ConfigKeys.themePartnerLogo,
  ConfigKeys.themePartnerLogoLink,
  ConfigKeys.themePartnershipHeadingLogo,
];

export const CreditScoreLink =
  'https://offers.evenfinancial.com/partner/ref/516a1f88-3d04-4581-b366-0f5d939ee72d/281519d1-7fad-45b7-a5a9-654a66646b4f';

export const EventScriptEvenDataVariable = 'EvenData';

export const lifeInsuranceExcludedDemandSubAccountIds = [1568, 1309, 1995, 1694, 1726, 1989, 1879, 1548, 1456];
