import * as React from 'react';

import classNames from 'classnames';
import { Col, Grid, Row } from 'react-flexbox-grid';

import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
}

export class GenericPage extends React.PureComponent<Props> {
  public render() {
    const { children } = this.props;

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <div className={classNames(styles.genericPage)}>
              <Row>
                <Col xs={12}>{children}</Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
