import * as React from 'react';

import { ConfigContext } from '../components/ConfigProvider';

export const useConfig = () => {
  const config = React.useContext(ConfigContext);
  if (config === undefined) {
    throw new Error('ConfigProvider cannot be found or has invalid config prop');
  }

  return config;
};
