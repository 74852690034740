import getConfig from 'next/config';

import type { StrictUnion } from '../utils/types';

interface BaseEnv {
  enableDdRum: boolean;
  environment: string | 'development' | 'local' | 'production';
  evenApiBase: string;
  publicApiToken: string;
  runtime: string;
  unleashProxyKey: string;
  version: string;
}

export interface PublicEnv extends Partial<BaseEnv> {
  runtime: 'browser';
}

export interface PrivateEnv extends Partial<BaseEnv> {
  privateApiToken?: string;
  runtime: 'node';
}

export type Environment = StrictUnion<PrivateEnv | PublicEnv>;

let envCache = null;

const hydrate = () => {
  const { publicRuntimeConfig = {}, privateRuntimeConfig = {} } = getConfig() || {};
  envCache = {
    ...process.env,
    ...publicRuntimeConfig,
    ...privateRuntimeConfig,
  };
};

export const getEnvironmentValues = (): Environment => {
  if (envCache === null) hydrate();
  return envCache;
};
