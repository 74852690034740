import * as React from 'react';

import { Col, Grid, Row } from 'react-flexbox-grid';
import { getLoanPartnerLogosByCopyVariant } from 'src/components/Sidebar';
import { TrustedPartnerContainer } from 'src/components/TrustedPartners/container';
import { useConfig } from 'src/hooks/useConfig';

import Handshake from '../../../assets/components/handshake.svg';
import Padlock from '../../../assets/components/padlock.svg';
import Stopwatch from '../../../assets/components/stopwatch.svg';
import { ConfigServiceDataKeys, TrustedPartnersSectionLocation } from '../../ConfigProvider';
import { ThemeConsumer } from '../../ThemeConsumer';

// TODO: Need embed step to determine if a user finish applying for a loan
// import { UserSentiment } from 'src/components/UserSentiment/container'
import { ThemeHeaderCopy } from '../../ThemeProvider';
import { getStatsCopy } from '../Stats';

import styles from './styles.module.scss';

import type { AugmentedConfig, Brand, FeatureFlag, PreferredOfferPartnerData } from '../../ConfigProvider';

interface StoreProps {
  brand: Brand | undefined;
  bridgePartner?: string;
  children: React.ReactNode;
  companyUuid: string;
  // loanStep?: LoanStep
  copyTemplateKey: ThemeHeaderCopy;
  featureFlags: FeatureFlag[];
  headerText: string;
  isAutoRefi: boolean;
  preferredOfferPartner?: PreferredOfferPartnerData;
  shouldShowPartnershipHeading: boolean | undefined;
  showTrustedPartners: TrustedPartnersSectionLocation;
}

export class PreferredPartnerPageView extends React.Component<StoreProps> {
  // public static Path = (partnerKey?: string, step?: LoanStep) => {
  //   const params = step ? `?${QueryParamKeys.Step}=${step}` : ``

  //   return `/${partnerKey}/preferred${params}`
  // }

  public render() {
    const {
      bridgePartner,
      copyTemplateKey,
      headerText,
      children,
      // loanStep,
      preferredOfferPartner,
      showTrustedPartners,
    } = this.props;

    const loanPartnerLogos = getLoanPartnerLogosByCopyVariant(copyTemplateKey);
    const selectedStats = getStatsCopy(copyTemplateKey);

    const showTrustedPartnersSection = showTrustedPartners === TrustedPartnersSectionLocation.PartnerPage;
    // const showUserSentiment = loanStep === LoanStep.Results

    return (
      <ThemeConsumer>
        {({
          colorBackgroundPrimary,
          colorBackgroundSecondary,
          colorPrimary,
          colorTextPrimary,
          colorTextSecondary,
          fontFamilyPrimary,
          fontFamilySecondary,
          headlineStyles,
        }) => (
          <div className={styles.preferredPartnerPage} style={{ backgroundColor: colorBackgroundSecondary }}>
            <div className={styles.preferredPartnerPageInner} style={{ backgroundColor: colorBackgroundPrimary }}>
              <Grid>
                <Row style={bridgePartner ? { display: 'none' } : {}}>
                  <Col xs className={styles.hero}>
                    {preferredOfferPartner && (
                      <div className={styles.logo}>
                        <img alt={preferredOfferPartner.displayName} src={preferredOfferPartner.imageUrl} />
                      </div>
                    )}
                    <div className={styles.tagline} style={{ color: colorTextPrimary, ...headlineStyles }}>
                      {headerText}
                    </div>
                    <div
                      className={styles.loanDetails}
                      style={{ color: colorTextSecondary, fontFamily: fontFamilySecondary }}
                    >
                      {selectedStats.map((stat) => (
                        <div key={stat.title} className={styles.loanDetailsItem}>
                          <strong>{stat.title}</strong> <span>{stat.text}</span>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row
                  center="xs"
                  className={styles.multiProductWidget}
                  style={bridgePartner ? { paddingTop: '16px' } : {}}
                >
                  <Col lg={8} md={10} xs={12}>
                    {children}
                  </Col>
                </Row>
                <Row center="xs" className={styles.instructionsRow}>
                  <Col className={styles.instructions} xs={10}>
                    <div className={styles.item}>
                      <div className={styles.icon} style={{ background: colorPrimary }}>
                        <Stopwatch />
                      </div>
                      <div className={styles.content}>
                        <h3 style={{ color: colorTextPrimary, fontFamily: fontFamilyPrimary }}>It's Quick</h3>
                        <p style={{ color: colorTextSecondary, fontFamily: fontFamilySecondary }}>
                          Just fill out our simple form and we'll check offers from all the top providers for you in
                          seconds.
                        </p>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.icon} style={{ background: colorPrimary }}>
                        <Handshake />
                      </div>
                      <div className={styles.content}>
                        <h3 style={{ color: colorTextPrimary, fontFamily: fontFamilyPrimary }}>The Right Match</h3>
                        <p style={{ color: colorTextSecondary, fontFamily: fontFamilySecondary }}>
                          Based on the info you provide, we match you with the right loan offer to meet your needs.
                        </p>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.icon} style={{ background: colorPrimary }}>
                        <Padlock />
                      </div>
                      <div className={styles.content}>
                        <h3 style={{ color: colorTextPrimary, fontFamily: fontFamilyPrimary }}>Safe &amp; Secure</h3>
                        <p style={{ color: colorTextSecondary, fontFamily: fontFamilySecondary }}>
                          Your data is secure. We use 256-bit encryption - a higher security standard than many banks.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
            {showTrustedPartnersSection && (
              <Grid>
                <Row center="xs">
                  <Col md={10} xs={12}>
                    <TrustedPartnerContainer staticTrustedPartners={loanPartnerLogos} />
                  </Col>
                </Row>
              </Grid>
            )}
            {/* showUserSentiment && <UserSentiment product={Product.Loan} /> */}
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

const headerTextSelector = (config: AugmentedConfig) => {
  const displayName = config.preferredOfferPartnerData?.displayName;
  const themeHeaderCopy = config['theme.headerCopy'] ?? ThemeHeaderCopy.Standard;
  if (themeHeaderCopy !== ThemeHeaderCopy.SLR) {
    return (
      config.headerText ??
      (displayName
        ? `Get Matched With Personal Loan Offers From ${displayName} And Other Lenders`
        : 'Get Matched With Personal Loan Offers In Less Than 60 Seconds')
    );
  } else {
    return config.headerText ?? 'Get Matched With Student Loan Refinancing Offers In Less Than 60 Seconds';
  }
};

interface Props {
  children?: React.ReactNode;
}

export const PreferredPartnerPage = ({ children }: Props) => {
  const config = useConfig();
  const bridgePartner = config.bridge_partner;
  const companyUuid = config['credentials.company_uuid'] ?? '';
  const featureFlags = config.featureFlags;
  const headerText = config.headerText ?? headerTextSelector(config);
  const brand = config.brand;
  const copyTemplateKey = config['theme.headerCopy'] ?? ThemeHeaderCopy.Standard;
  // Not sure how this works in consumer since ConfigServiceDataKeys.defaultLoanPurpose key does not actually exist in config
  // const isAutoRefi = config[ConfigServiceDataKeys.defaultLoanPurpose] === LoanPurposeOptions.AUTO_REFINANCE
  const isAutoRefi = false;
  const preferredOfferPartner = config.preferredOfferPartnerData;
  // This is from coming from embed
  // const selectedStep = uiSelectedStepSelector(state)
  const shouldShowPartnershipHeading = config.shouldShowPartnershipHeading;
  const showTrustedPartners =
    (config[ConfigServiceDataKeys.showTrustedPartners] as TrustedPartnersSectionLocation) ??
    TrustedPartnersSectionLocation.None;

  // let loanStep: LoanStep | undefined

  // if (selectedStep && selectedStep[Product.Loan]) {
  //   loanStep = selectedStep[Product.Loan]
  // }

  return (
    <PreferredPartnerPageView
      brand={brand}
      bridgePartner={bridgePartner}
      companyUuid={companyUuid}
      copyTemplateKey={copyTemplateKey}
      featureFlags={featureFlags}
      headerText={headerText}
      isAutoRefi={isAutoRefi}
      preferredOfferPartner={preferredOfferPartner}
      shouldShowPartnershipHeading={shouldShowPartnershipHeading}
      showTrustedPartners={showTrustedPartners}
      // loanStep={loanStep}
    >
      {children}
    </PreferredPartnerPageView>
  );
};
