import * as React from 'react';

import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import classNames from 'classnames';
import { Col, Grid, Row } from 'react-flexbox-grid';

import { useConfig } from '../../../hooks/useConfig';
import { Brand, FeatureFlag } from '../../ConfigProvider';

import EvenWordmark from './even-wordmark.svg';
import FionaWordmark from './fiona-wordmark.svg';
import styles from './styles.module.scss';

interface Props {
  brand: Brand;
  partnerKey: string;
  shouldShowPartnershipHeading: boolean;
}

type PartnershipHeadingProps = Pick<Props, 'brand' | 'shouldShowPartnershipHeading' | 'partnerKey'>;

const PartnershipHeading = ({
  brand,
  shouldShowPartnershipHeading,
  partnerKey,
}: PartnershipHeadingProps): JSX.Element => {
  const { featureFlags, partnerDisplayName } = useConfig();
  const { partnershipHeadingLogo } = useTheme();
  if (featureFlags.includes(FeatureFlag.MoneylionCreator) && partnerDisplayName) {
    return (
      <div className={styles.textPartnershipHeading}>
        <p>In partnership with</p>
        <p>
          <strong>{partnerDisplayName}</strong>
        </p>
      </div>
    );
  }

  if (!shouldShowPartnershipHeading) {
    return <></>;
  }

  switch (brand) {
    case Brand.Fiona:
      if (partnershipHeadingLogo) {
        return (
          <>
            <p>in&nbsp;partnership&nbsp;with</p>
            <img alt={partnerKey} src={partnershipHeadingLogo} />
          </>
        );
      } else {
        // Otherwise show Fiona wordmark
        return (
          <>
            <p>in&nbsp;partnership&nbsp;with</p>
            <span className={styles.fionaWordmark}>
              <FionaWordmark />
            </span>
          </>
        );
      }
    case Brand.Even:
    default:
      // If Even partner and shouldShowPartnershipHeading is true, show the Even logo
      return (
        <div className={styles.evenWordmark}>
          <p>powered&nbsp;by</p>
          <EvenWordmark />
        </div>
      );
  }
};
export const PrimaryNavigation = ({ partnerKey, shouldShowPartnershipHeading, brand }: Props) => {
  const { colorTextSecondary, partnerLogo, partnerLogoLink } = useTheme();

  return (
    <div className={classNames(styles.partnerHeader)}>
      <Grid>
        <Row between="xs">
          <Col className={classNames(styles.partnerHeaderLogo, styles.partnerHeaderLogoSolo)} xs={6}>
            <a href={partnerLogoLink} rel={'nofollow noreferrer'} target="_blank">
              <img alt={`${partnerKey} logo`} className={classNames(styles.partnerHeaderLogoImage)} src={partnerLogo} />
            </a>
          </Col>
          {
            <Col
              className={classNames(
                styles.partnerHeaderPoweredBy,
                css`
                  color: ${colorTextSecondary};
                `
              )}
              xs={6}
            >
              <PartnershipHeading
                brand={brand}
                partnerKey={partnerKey}
                shouldShowPartnershipHeading={shouldShowPartnershipHeading}
              />
            </Col>
          }
        </Row>
      </Grid>
    </div>
  );
};
